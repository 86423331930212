import { FaceSmileIcon, SparklesIcon, StarIcon } from "@heroicons/react/24/outline";
import featureImageOne from "@sections/Features/feature-image-one.png";
import featureImageThree from "@sections/Features/feature-image-three.png";
import featureImageTwo from "@sections/Features/feature-image-two.png";
import { copy } from "@sections/copy/copy";
import { useLayoutEffect, useRef, useState } from "react";

import { Heading, Paragraph } from "@fronterahealth/frontera-ui-components";
import { HeroIconType } from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";

import { AnimatedSection } from "@components/AnimatedSection";
import useMediaQuery from "@hooks/useMediaQuery";
import { useSize } from "@hooks/useSize";

const awsMarketingVideoSource =
  "https://637423311956-prod-static.s3.us-west-2.amazonaws.com/Marketing/assessment-builder-website-video.mp4";

const awsMarketingPosterSource =
  "https://637423311956-prod-static.s3.us-west-2.amazonaws.com/Marketing/assessment-builder-website-poster.png";

interface FeatureCardInterface {
  title: string;
  subtitle: string;
  img: string;
  Icon: HeroIconType;
}
const FeatureCard: React.FC<FeatureCardInterface> = ({ title, subtitle, Icon }) => {
  return (
    <div className="flex flex-col   px-4 pt-4 justify-between">
      <div className="flex flex-col items-center ">
        <div className="p-4 border border-green-500 rounded-2xl  flex items-center justify-center mb-4">
          <Icon className="w-6 h-6 text-green-500" />
          {/* <SparklesIcon className="w-4 h-4 text-green-400" /> */}
        </div>
        <Heading className="text-white" type="h3">
          {title}
        </Heading>
        <Paragraph className="mt-2 text-hatch-200">
          <span className="text-hatch-200">{subtitle}</span>
        </Paragraph>
      </div>
    </div>
  );
};

// NOTE: BRING THIS BACK WHEN INCLUDING VIDEO
const VIDEO_CONTAINER_CLASS_NAME_DESKTOP = `max-h-[450px] min-h-[450px] min-w-[800px] max-w-[800px] rounded-[16px] `;
const VIDEO_CONTAINER_CLASS_NAME_MOBILE = `rounded-[8px]`;
const ASPECT_RATIO_FOR_VIDEOS = 1.77;

export const Features: React.FC = () => {
  // NOTE: BRING THIS BACK WHEN INCLUDING VIDEO
  const videoContainerRef = useRef<HTMLVideoElement | null>(null);

  const isDesktop = useMediaQuery("(min-width: 960px)");

  // NOTE: BRING THIS BACK WHEN INCLUDING VIDEO
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useLayoutEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { height } = useSize(videoContainerRef);

  // 32px for padding, 2 for border
  const totalVideoContainerHeight = height + 32 + 2;

  return (
    <div
      id="feature-part-one-section"
      style={{
        backgroundImage: "linear-gradient(180deg, #F7F6F5 0%, #F7F6F5 50%, #FFFFFF 50.1%, #FFFFFF 100%)",
      }}
      className={`relative flex flex-col ${isDesktop ? "" : "px-4"} pb-12 justify-start items-center `}
    >
      {/* NOTE: BRING THIS BACK WHEN INCLUDING VIDEO  */}
      <div
        // ref={videoContainerRef}
        className={`z-10 border border-limestone-200 ${isDesktop ? "p-4 rounded-[32px]  " : "p-2 rounded-[16px]  flex justify-center items-center flex-col"} bg-[rgba(255,255,255,.1)]`}
      >
        <div
          style={
            isDesktop
              ? {}
              : {
                  minWidth: `${screenWidth - 32}px`,
                  maxWidth: `${screenWidth - 32}px`,
                  minHeight: `${(screenWidth - 32) / ASPECT_RATIO_FOR_VIDEOS}px`,
                }
          }
          className={` bg-hatch-100 flex-col flex items-center justify-center  ${isDesktop ? VIDEO_CONTAINER_CLASS_NAME_DESKTOP : VIDEO_CONTAINER_CLASS_NAME_MOBILE} `}
        >
          <video
            preload="metadata"
            poster={awsMarketingPosterSource}
            className={`${isDesktop ? `rounded-[16px]` : `rounded-[8px]`} w-full max-h-[450px]`}
            ref={videoContainerRef}
            controls
            src={awsMarketingVideoSource}
          />
        </div>
      </div>
      <div
        style={{
          // NOTE: BRING THIS BACK WHEN INCLUDING VIDEO
          marginTop: `-${totalVideoContainerHeight / 2}px`,
          paddingTop: `${totalVideoContainerHeight / 2 + 32}px`,
          // paddingTop: 48,
        }}
        className={`min-w-[calc(100vw-64px)] flex items-center flex-col justify-center text-center bg-hatch text-white pb-24 px-12  ${isDesktop ? "rounded-3xl" : "-ml-4 -mr-4"}`}
      >
        <AnimatedSection>
          <div className="flex flex-col gap-y-4">
            <Heading id="features" type="h1" className="text-white">
              {copy.features.title}
            </Heading>
          </div>
          <div className={`flex ${isDesktop ? "flex-row gap-x-4" : "flex-col gap-y-4"}  mt-16`}>
            <FeatureCard
              title={copy.features.firstFeature.title}
              subtitle={copy.features.firstFeature.subtitle}
              img={featureImageOne}
              Icon={SparklesIcon}
            />
            <FeatureCard
              title={copy.features.secondFeature.title}
              subtitle={copy.features.secondFeature.subtitle}
              img={featureImageTwo}
              Icon={StarIcon}
            />
            <FeatureCard
              title={copy.features.thirdFeature.title}
              subtitle={copy.features.thirdFeature.subtitle}
              img={featureImageThree}
              Icon={FaceSmileIcon}
            />
          </div>
        </AnimatedSection>
      </div>
    </div>
  );
};
