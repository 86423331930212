import { copy } from "@sections/copy/copy";

import { Heading, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { AnimatedSection } from "@components/AnimatedSection";
import useMediaQuery from "@hooks/useMediaQuery";

interface PriceCardProps {
  title: string;
  subtitle: string;
  buttonText: string;
  buttonHref: string;
  body: React.ReactNode;
}
const PriceCard: React.FC<PriceCardProps> = ({ title, subtitle, body }) => {
  return (
    <div className=" min-h-[240px] flex flex-col p-8 border-limestone-100 border bg-white items-start text-left rounded-[32px]">
      <Paragraph displayType="loud" colorType="primary" className="mb-4">
        {title}
      </Paragraph>
      <Paragraph>{subtitle}</Paragraph>
      <div className="my-4">{body}</div>
    </div>
  );
};

export const Pricing: React.FC = () => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
    <div
      id="pricing-section"
      className={`flex flex-col ${isDesktop ? "px-36" : "px-4"} py-12 justify-start items-center bg-limestone-50 w-full`}
    >
      <div className="max-w-screen-xl flex items-center flex-col justify-center text-center">
        <AnimatedSection percentageInViewBeforeTriggering={1}>
          <div className="flex flex-col gap-y-4 my-8">
            <Heading id="pricing" type="h1">
              {copy.pricing.title}
            </Heading>
            <Paragraph className="max-w-screen-sm">{copy.pricing.subtitle}</Paragraph>
          </div>
        </AnimatedSection>

        <AnimatedSection percentageInViewBeforeTriggering={1}>
          <div className={`${isDesktop ? "grid grid-cols-2 items-center gap-x-8" : "flex flex-col gap-y-8"}`}>
            <PriceCard
              buttonText="Get Started"
              buttonHref={"https://portal.fronterahealth.com?ref=website-pricing-get-started"}
              title={copy.pricing.individual.title}
              subtitle={copy.pricing.individual.subtitle}
              body={
                <div className="flex items-end">
                  <Heading type="h1">${copy.pricing.individual.costPerAssessment}</Heading>
                  <Small className="ml-1">{copy.pricing.individual.costLabel}</Small>
                </div>
              }
            />
            <PriceCard
              buttonText="Contact Us"
              buttonHref={"mailto:support@fronterahealth.com"}
              title={copy.pricing.group.title}
              subtitle={copy.pricing.group.subtitle}
              body={
                <div className="flex items-end">
                  <a href={"mailto:info@fronterahealth.com"}>
                    <Heading style={{}} className=" hover:text-green-700 underline" type="h1">
                      {copy.pricing.group.ctaButton}
                    </Heading>
                  </a>
                </div>
              }
            />
          </div>
        </AnimatedSection>
      </div>
    </div>
  );
};
