import caspLogo from "@sections/SiteFooter/casp-logo.png";
import hippaLogo from "@sections/SiteFooter/hippa-logo.png";
import logo from "@sections/SiteFooter/white-logo.png";
import React from "react";

import { Collapsible, Heading, Small } from "@fronterahealth/frontera-ui-components";

import useMediaQuery from "@hooks/useMediaQuery";

export const SiteFooter: React.FC = () => {
  const isDesktop = useMediaQuery("(min-width: 960px)");

  if (!isDesktop) {
    return (
      <div
        className={`flex items-start w-full justify-between flex-col  bg-hatch-950 ${isDesktop ? "px-36 py-20" : "py-20 px-8"} w-full gap-y-4`}
      >
        <Collapsible
          headerIsClickable
          headerContent={
            <div className="flex w-full">
              <Heading type="h3" className="text-hatch-300">
                <span className="text-hatch-300">Product</span>
              </Heading>
            </div>
          }
          bodyContent={
            <div className="flex flex-col gap-y-2 text-gray-100 py-4">
              <a href="#features" className="hover:text-hatch-300">
                <span className="">Features</span>
              </a>
              <a href="#pricing" className="hover:text-hatch-300">
                <span className="">Pricing</span>
              </a>

              <a href="#faq" className="hover:text-hatch-300">
                <span className="">FAQ</span>
              </a>
            </div>
          }
        />
        <Collapsible
          headerIsClickable
          headerContent={
            <div className="flex w-full">
              <Heading type="h3" className="text-hatch-300">
                <span className="text-hatch-300">Resources</span>
              </Heading>
            </div>
          }
          bodyContent={
            <div className="flex flex-col gap-y-2 text-gray-100 py-4">
              <div className="flex flex-col gap-y-2 text-gray-100 py-4">
                <a href="/baa" className="hover:text-hatch-300">
                  <span className="">BAA</span>
                </a>
                <a href="/terms-of-service" className="hover:text-hatch-300">
                  <span className="">Terms of Service</span>
                </a>

                <a href="/privacy-policy" className="hover:text-hatch-300">
                  <span className="">Privacy Policy</span>
                </a>
              </div>
            </div>
          }
        />
        <Collapsible
          headerIsClickable
          headerContent={
            <div className="flex w-full">
              <Heading type="h3" className="text-hatch-300">
                <span className="text-hatch-300">Company</span>
              </Heading>
            </div>
          }
          bodyContent={
            <div className="flex flex-col gap-y-2 text-gray-100 py-4">
              <a className="hover:text-hatch-300" href="https://boards.greenhouse.io/fronterahealth">
                <span className=""></span>
              </a>
            </div>
          }
        />
        <div className="flex flex-col items-center text-gray-100 my-8 w-full">
          <div className="flex items-center">
            <img className="max-h-[40px]" src={logo} alt="" />
          </div>
          <div className="flex items-center mt-4 space-x-4">
            <img className="max-w-[64px]" src={caspLogo} alt="" />
            <img className="max-w-[64px]" src={hippaLogo} alt="" />
          </div>

          <div className="flex justify-center items-center mt-4 gap-x-2 text-center w-full">
            <Small className="text-gray-100 ">Frontera Health ©2024</Small>
          </div>
        </div>
      </div>
    );
  }

  if (isDesktop) {
    return (
      <div
        className={`flex items-start w-full justify-between flex-col bg-hatch-950 pt-20 pb-4 ${isDesktop ? "px-36" : ""}  `}
      >
        <div className={`flex items-start w-full justify-between   `}>
          <div className="flex flex-col text-gray-100">
            <div className="flex items-center">
              <img className="max-h-[40px]" src={logo} alt="" />
            </div>

            <div className="flex items-center mt-4 space-x-4">
              <img className="max-w-[64px]" src={caspLogo} alt="" />
              <img className="max-w-[64px]" src={hippaLogo} alt="" />
            </div>
          </div>
          {isDesktop ? (
            <div className="flex gap-x-12">
              <div className="flex flex-col gap-y-4 text-gray-100">
                <span className="font-bold text-hatch-300">Product</span>
                <div className="flex flex-col gap-y-2">
                  <a href="#demo" className="hover:text-hatch-300">
                    <span className="">Demo</span>
                  </a>

                  <a href="#features" className="hover:text-hatch-300">
                    <span className="">Features</span>
                  </a>
                  <a href="#pricing" className="hover:text-hatch-300">
                    <span className="">Pricing</span>
                  </a>

                  <a href="#faq" className="hover:text-hatch-300">
                    <span className="">FAQ</span>
                  </a>
                </div>
              </div>
              <div className="flex flex-col gap-y-4 text-gray-100">
                <span className="font-bold text-hatch-300">Resources</span>
                <div className="flex flex-col gap-y-2">
                  <a href="/baa" className="hover:text-hatch-300">
                    <span className="">BAA</span>
                  </a>
                  <a href="/terms-of-service" className="hover:text-hatch-300">
                    <span className="">Terms of Service</span>
                  </a>

                  <a href="/privacy-policy" className="hover:text-hatch-300">
                    <span className="">Privacy Policy</span>
                  </a>
                </div>
              </div>
              <div className="flex flex-col gap-y-4 text-gray-100">
                <span className="font-bold text-hatch-300">Company</span>
                <div className="flex flex-col gap-y-2">
                  <a className="hover:text-hatch-300" href="https://boards.greenhouse.io/fronterahealth">
                    <span className="">Careers</span>
                  </a>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="w-[100vw] -ml-[144px] border-t border-t-limestone-50 border-opacity-20 p-1 flex justify-center mt-20">
          <div className="flex items-center mt-4 gap-x-2">
            <Small className="text-gray-100 ">Frontera Health ©2024</Small>
          </div>
        </div>
      </div>
    );
  }
};
