import { FAQ } from "@sections/FAQ";
import { Features } from "@sections/Features/Features";
import { HowItWorks } from "@sections/HowItWorks/HowItWorks";
import { Pricing } from "@sections/Pricing/Pricing";
import { SiteFooter } from "@sections/SiteFooter/SiteFooter";
import { SiteHeader } from "@sections/SiteHeader";
import { Splash } from "@sections/Splash/Splash";
import { Tour } from "@sections/Tour/Tour";
import { useEffect, useState } from "react";

import { AnimatedSection } from "@components/AnimatedSection";
import useMediaQuery from "@hooks/useMediaQuery";

export const App = () => {
  const isDesktop = useMediaQuery("(min-width: 960px)");

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleHashChange = () => {
      if (sidebarOpen) setSidebarOpen(false);
    };
    window.addEventListener("hashchange", handleHashChange);

    return () => window.removeEventListener("hashchange", handleHashChange);
  }, [sidebarOpen, setSidebarOpen]);

  if (window.location.pathname === "/terms-of-service") return null;
  if (window.location.pathname === "/baa") return null;
  if (window.location.pathname === "/privacy-policy") return null;

  return (
    <div className="flex flex-col relative ">
      <SiteHeader />
      <Splash />
      <Features />

      <AnimatedSection>
        <HowItWorks />
      </AnimatedSection>
      {isDesktop ? (
        <AnimatedSection>
          <Tour />
        </AnimatedSection>
      ) : null}
      <Pricing />
      <FAQ />
      <SiteFooter />
    </div>
  );
};

export default App;
