import { Heading, Paragraph, Small } from "@fronterahealth/frontera-ui-components";
import logo from "@fronterahealth/frontera-ui-components/assets/logo.png";

const topSectionSpacing = "space-y-4 mb-6 flex flex-col items-start";

export const PrivacyPolicy = () => {
  if (window.location.pathname !== "/privacy-policy") return null;
  return (
    <div className="relative">
      <div className="terms-of-use-container p-4 max-w-screen-lg mx-auto gap-y-4 flex flex-col items-center py-20 relative">
        <div className="my-4 flex max-w-[192px] h-[40px] max-h-[40px]  items-center">
          <img className="max-h-[32px]" src={logo} alt="" />
        </div>
        <Heading type="h1">Frontera Health Privacy Policy</Heading>
        <Small>Last Revised: September 03, 2024</Small>

        <div className="flex flex-col items-start">
          {/* Introduction */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="introduction" type="h2">
              Applicability
            </Heading>
            <Paragraph>
              This Policy describes Frontera Health New Mexico, LLC’s information collection and use practices on the
              places where it appears. It does not describe our practices on sites or places where it does not appear.
              It does not apply to information we collect from our employees or contractors. This Policy does not
              address our privacy practices for any protected health information we collect as a covered entity under
              the Health Insurance Portability and Accountability Act of 1996, including any protected health
              information collected through the site. We address those practices in our Notice of Privacy Practices.
            </Paragraph>
          </section>

          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="agreement" type="h2">
              Information We Collect
            </Heading>
            <Paragraph>
              The type of personal information we collect depends on your relationship with us. It also depends on how
              you interact with us. Described below by category is the information we may collect.
            </Paragraph>
            <Paragraph>
              {" "}
              <b>Contact information</b>. We may collect your name and phone number. We also collect your address and
              email address.
            </Paragraph>{" "}
            <Paragraph>
              <b>Location information.</b> We may collect information about your location. This may include zip code and
              state.{" "}
            </Paragraph>
            <Paragraph>
              <b>Professional information.</b> We collect resumes, education, and employment history from job
              applicants. This includes information about your skills and qualifications for the position.{" "}
            </Paragraph>
            <Paragraph>
              <b>Site usage information.</b> We collect logs and session data when you visit our website. This may
              include location information. We collect browser and operating system information. We collect what site
              you came from or what site you visit when you leave us. We also look at the areas of our site you visit
              and any actions you make. We collect your IP address. We also collect device identifiers. This might
              include your hardware model.
            </Paragraph>
          </section>
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="agreement" type="h2">
              Business Purposes for Collecting Information
            </Heading>
            <Paragraph>
              We use information to communicate with you. We use information to respond to your questions. We also use
              information to communicate with you about our policies and terms. If you apply for a job with us, we use
              information to process your application.
            </Paragraph>
            <Paragraph>
              We use information to provide and improve our site. We use information to make our site better. This may
              include customizing your experience with us. We also use information to improve our services.
            </Paragraph>
            <Paragraph>
              We use information for marketing purposes. We may use information to let you know about new developments
              and services. This may be about our offers or offerings of third parties. We also use information to send
              you newsletters or other content we think you may find interesting.
            </Paragraph>
            <Paragraph>
              Targeted ads. As permitted by law, we also use certain categories of information to serve you with ads we
              think you will find interesting. We might do this based on your browsing habits or other activities. These
              could be on ours or others’ platforms. (This is often called interest-based, targeted or “cross
              contextual” advertising.) We might do this on social media platforms. This may occur after you leave our
              sites or platforms and may encourage you to return.
            </Paragraph>
            <Paragraph>
              We use information to protect ourselves and others. We use information to protect our company. For
              example, to identify fraud or secure our systems. We may also use information to protect our website
              visitors.
            </Paragraph>
            <Paragraph>
              We use information for other purposes as permitted by law or as we may disclose to you.
            </Paragraph>
          </section>
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="agreement" type="h2">
              Methods of Collecting Information
            </Heading>
            <Paragraph>
              We collect information directly from you. We collect information if you contact us or submit it to us.
              This could be in person. It may also be over the phone or online.
            </Paragraph>
            <Paragraph>
              We collect information passively. We collect information about users over time and across different
              websites. We may do this on our websites and in emails that we send to you. We also work with third
              parties that collect personal information this way. We may use several common tracking tools to collect
              information. Tracking tools include browser cookies and web beacons. To learn more about cookies visit the{" "}
              <a
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://consumer.ftc.gov/articles/how-protect-your-privacy-online#understanding_cookies"
              >
                FTC's Online Tracking Page
              </a>
              . We may work with others to gather information on our platforms and elsewhere.
            </Paragraph>
            <Paragraph>
              We collect information about you from others. For example, business partners may provide information about
              you to us. We may also collect information from referrals or industry associations or other public
              sources.
            </Paragraph>
          </section>
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="agreement" type="h2">
              We Combine Information
            </Heading>
            <Paragraph>
              We combine information we collect from you on the website with information we receive from you offline. We
              combine information that we have collected across other third party sites. We combine information across
              devices, such as computers and mobile devices. We also combine information you provide with information we
              obtain from third parties.
            </Paragraph>
          </section>
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="agreement" type="h2">
              When We Share Information with Others
            </Heading>
            <Paragraph>
              We may share information within our family of companies. We may share information with our affiliates,
              parent company, and other related companies. This includes current and future companies.
            </Paragraph>
            <Paragraph>
              We share information with vendors who perform services on our behalf. We may share information with
              trusted vendors that provide us with services. For example, vendors who help us host and operate the site.
            </Paragraph>
            <Paragraph>
              We share information if we think we have to in order to comply with the law or to protect ourselves. We
              share information we collect about you to respond to a court order or subpoena. We share information in
              response to a government agency or investigatory body request. We share information we collect when we
              investigate potential fraud.
            </Paragraph>
            <Paragraph>
              We share information with any successor to all or part of our business. If all or part of our business was
              sold, we may share information as part of that transaction. If there is a merger or acquisition, we may
              also share your information. If there is a financing or bankruptcy, we may share your information.
            </Paragraph>
            <Paragraph>
              We share information as permitted by law and for other reasons we may describe to you.
            </Paragraph>
          </section>
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="agreement" type="h2">
              You Have Certain Choices
            </Heading>
            <Paragraph>
              You can opt out of receiving our marketing emails. To stop receiving our promotional emails, follow the
              “unsubscribe” instructions in any promotional email communication you get from us. Even if you opt out of
              getting marketing messages, we will still send you transactional messages. For example, this includes
              responses to your questions.
            </Paragraph>
            <Paragraph>OUR COOKIE AND AD POLICY</Paragraph>
            <Paragraph className="pl-4">
              You can control cookies and tracking tools. Your browser may give you the ability to control cookies or
              other tracking tools. How you do so depends on the type of tool. Certain browsers can be set to reject
              browser cookies. If you block cookies, certain features on our sites may not work. If you block or reject
              cookies, not all of the tracking described here will stop. Additionally, the Self-Regulatory Program for
              Online Behavioral Advertising provides consumers with the ability to manage certain choices online here
              and provides a tool for managing mobile choices here.
            </Paragraph>
            <Paragraph className="pl-4">
              Our Do Not Track Policy: Some browsers have “do not track” features that allow you to tell a website not
              to track you. These features are not all uniform. We do not currently respond to those signals.
            </Paragraph>
            <Paragraph className="pl-4">Options you make are browser and device specific.</Paragraph>
          </section>
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="agreement" type="h2">
              How We Protect Information
            </Heading>
            <Paragraph>
              We use reasonable security measures as required by relevant law. The Internet is not 100% secure. We
              cannot promise that your use of our sites or apps will be completely safe. We encourage you to use caution
              when using the Internet. This includes not sharing your passwords.
            </Paragraph>
          </section>
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="agreement" type="h2">
              We Store Information in the United States
            </Heading>
            <Paragraph>
              Information we collect is stored in the United States. This site is intended for people who are in the
              United States. If you live outside of the United States and choose to use this site you understand that it
              is at your own risk. If you live outside of the US, you understand and agree that we may transfer your
              information to the US. Our sites and businesses are subject to US laws, which may not afford the same
              level of protection as those in your country. By submitting your information, you agree to the processing
              of it in the US.
            </Paragraph>
          </section>
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="agreement" type="h2">
              Third Party Sites and Links
            </Heading>
            <Paragraph>
              If you click on a link to a third party site, you will be taken to websites we do not control. This
              includes our pages on social media sites. This policy does not apply to the privacy practices of these
              websites. Read the privacy policy of other websites carefully. We are not responsible for these third
              party practices.
            </Paragraph>
          </section>
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="agreement" type="h2">
              These Sites are Not Intended For Children
            </Heading>
            <Paragraph>
              Our sites are meant for adults. We do not knowingly collect personally identifiable information online
              from children under 13. If you are a parent or legal guardian and think your child under 13 has given us
              information, you can email us. You can also write to us at the address listed at the end of this policy.
              Please mark your inquiries “COPPA Information Request.” Parents, you can learn more about how to protect
              children’s privacy on-line{" "}
              <a
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://www.ftc.gov/tips-advice/business-center/privacy-and-security/children%27s-privacy"
              >
                here
              </a>
              .
            </Paragraph>
          </section>
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="agreement" type="h2">
              Contact Us and Updates to This Policy
            </Heading>
            <Paragraph>
              If you have questions about this Policy, you can email us at{" "}
              <a
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="mailto:support@fronterahealth.com"
              >
                support@fronterahealth.com
              </a>
              . We may make changes or updates to this Policy. We will notify you of any material changes to this Policy
              as required by law. All changes will also be posted on our website. Please check our site periodically for
              updates.
            </Paragraph>
          </section>
        </div>
      </div>
    </div>
  );
};
