import { Heading, Paragraph, Small } from "@fronterahealth/frontera-ui-components";
import logo from "@fronterahealth/frontera-ui-components/assets/logo.png";

const topSectionSpacing = "space-y-4 mb-6 flex flex-col items-start";
const subSectionSpacing = `${topSectionSpacing} pl-4`;

export const TermsOfUse = () => {
  if (window.location.pathname !== "/terms-of-service") return null;
  return (
    <div className="relative">
      <div className="terms-of-use-container p-4 max-w-screen-lg mx-auto gap-y-4 flex flex-col items-center py-20 relative">
        <div className="my-4 flex max-w-[192px] h-[40px] max-h-[40px]  items-center">
          <img className="max-h-[32px]" src={logo} alt="" />
        </div>
        <Heading type="h1">Assessment Builder Terms of Service</Heading>
        <Small>Last Revised: September 03, 2024</Small>

        <div className="flex flex-col items-start">
          {/* Introduction */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="introduction" type="h2">
              Introduction
            </Heading>
            <Paragraph>
              These Assessment Builder Terms of Service (these “Terms”) are an agreement that describes your rights and
              responsibilities as a user of the online assessment builder Assessment Builder report generation tool made
              available by Frontera Health, Inc. (“Frontera,” “we,” or “us”). This page explains the terms by which you
              may access and use our assessment builder platform and related documentation (collectively, the
              “Assessment Builder”). By accessing or using the Assessment Builder, or by clicking a button or checking a
              box marked “I Agree” (or something similar), you signify that you have read, understood, and agree to be
              bound by these Terms. In addition, you acknowledge that you have read and understood our{" "}
              <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="/privacy-policy">
                Privacy Notice
              </a>
              . Frontera reserves the right to modify these terms and will provide notice of material changes as
              described below. These Terms apply to all users of the Assessment Builder (“Users”). PLEASE READ THESE
              TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THESE TERMS CONTAIN A MANDATORY INDIVIDUAL
              ARBITRATION PROVISION IN SECTION 12.2 (THE “ARBITRATION AGREEMENT”) AND CLASS ACTION/JURY TRIAL WAIVER
              PROVISION IN SECTION 12.3 (THE “CLASS ACTION/JURY TRIAL WAIVER”) THAT REQUIRE, UNLESS YOU OPT OUT PURSUANT
              TO THE INSTRUCTIONS IN SECTION 12.2, THE EXCLUSIVE USE OF FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL
              BASIS TO RESOLVE DISPUTES BETWEEN YOU AND US, INCLUDING ANY CLAIMS THAT AROSE OR WERE ASSERTED BEFORE YOU
              AGREED TO THESE TERMS. TO THE FULLEST EXTENT PERMITTED BY LAW, YOU EXPRESSLY WAIVE YOUR RIGHT TO SEEK
              RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS, AS WELL AS YOUR RIGHT TO PARTICIPATE AS
              A PLAINTIFF OR CLASS MEMBER IN ANY CLASS, COLLECTIVE, PRIVATE ATTORNEY GENERAL OR REPRESENTATIVE ACTION OR
              PROCEEDING.
            </Paragraph>
          </section>

          {/* 1. Service */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="service">
              1. Service
            </Heading>
            <Paragraph>
              Frontera’s Assessment Builder automates the assessment process for children in need of autism therapy,
              empowering board certified behavior analyst to efficiently create personalized comprehensive initial
              assessments.
            </Paragraph>
          </section>

          {/* 1. Service Subsections */}
          <section className={subSectionSpacing}>
            <section className={topSectionSpacing}>
              <Heading type="h3">1.1 Eligibility</Heading>
              <Paragraph>
                This is a contract between you and Frontera. You must read and agree to these terms before using the
                Frontera Service. If you do not agree, you may not use the Assessment Builder. You may use the
                Assessment Builder only if you can form a binding contract with Frontera, and only in compliance with
                these Terms and all applicable local, state, national, and international laws, rules and regulations.
                Any use or access to the Assessment Builder by anyone under 18 is strictly prohibited and in violation
                of these Terms. The Assessment Builder is not available to any Users previously removed from the
                Assessment Builder by Frontera.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">1.2 Limited License</Heading>
              <Paragraph>
                Subject to the terms and conditions of these Terms, you are hereby granted a non-exclusive, limited,
                non-transferable, freely revocable license to use the Assessment Builder as permitted by the features of
                the Assessment Builder. Frontera reserves all rights not expressly granted herein in the Assessment
                Builder and the Frontera Content (as defined below). Frontera may terminate this license at any time for
                any reason or no reason.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">1.3 User Accounts</Heading>
              <Paragraph>
                Your account on the Assessment Builder (your “User Account”) gives you access to the Assessment Builders
                and functionality that we may establish and maintain from time to time and in our sole discretion. If
                you open a User Account on behalf of a Frontera, organization, or other entity, then (i) “you” includes
                you and that entity, and (ii) you represent and warrant that you are an authorized representative of the
                entity with the authority to bind the entity to these Terms, and that you agree to these Terms on the
                entity’s behalf.
              </Paragraph>
              <Paragraph>
                {" "}
                By connecting to Frontera with a third-party service (such as using a Google account), you give us
                permission to access and use your information from that service as permitted by that service, and to
                store your log-in credentials for that service.{" "}
              </Paragraph>
              <Paragraph>
                You may never use another User’s User Account without permission. When creating your User Account, you
                must provide accurate and complete information, and you must keep this information up to date. You are
                solely responsible for the activity that occurs on your User Account, and you must keep your User
                Account password secure. We encourage you to use “strong” passwords (passwords that use a combination of
                upper and lower case letters, numbers and symbols) with your User Account. You must notify Frontera
                immediately of any breach of security or unauthorized use of your User Account. Frontera will not be
                liable for any losses caused by any unauthorized use of your User Account.
              </Paragraph>
              <Paragraph>
                {" "}
                You may control your User profile and how you interact with the Assessment Builder by changing the
                settings in your settings page. By providing Frontera your email address you consent to our using the
                email address to send you Service-related notices, including any notices required by law, in lieu of
                communication by postal mail. We may also use your email address to send you other messages, such as
                changes to features of the Assessment Builder and special offers. If you do not want to receive such
                email messages, you may opt out or change your preferences in your settings page. Opting out may prevent
                you from receiving email messages regarding updates, improvements, or offers.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">1.4 Service Rules</Heading>
              <Paragraph>
                You agree not to engage in any of the following prohibited activities: (i) copying, distributing, or
                disclosing any part of the Assessment Builder in any medium, including without limitation by any
                automated or non-automated “scraping”; (ii) using any automated system, including without limitation
                “robots,” “spiders,” “offline readers,” etc., to access the Assessment Builder in a manner that sends
                more request messages to the Frontera servers than a human can reasonably produce in the same period of
                time by using a conventional on-line web browser; (iii) transmitting spam, chain letters, or other
                unsolicited email; (iv) attempting to interfere with, compromise the system integrity or security or
                decipher any transmissions to or from the servers running the Assessment Builder; (v) taking any action
                that imposes, or may impose at our sole discretion an unreasonable or disproportionately large load on
                our infrastructure; (vi) uploading invalid data, viruses, worms, or other software agents through the
                Assessment Builder; (vii) collecting or harvesting any personally identifiable information, including
                account names, from the Assessment Builder; (viii) using the Assessment Builder for any commercial
                solicitation purposes; (ix) impersonating another person or otherwise misrepresenting your affiliation
                with a person or entity, conducting fraud, hiding or attempting to hide your identity; (x) interfering
                with the proper working of the Assessment Builder; (xi) accessing any content on the Assessment Builder
                through any technology or means other than those provided or authorized by the Assessment Builder; or
                (xii) bypassing the measures we may use to prevent or restrict access to the Assessment Builder,
                including without limitation features that prevent or restrict use or copying of any content or enforce
                limitations on use of the Assessment Builder or the content therein.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">1.5 Changes to the Assessment Builder</Heading>
              <Paragraph>
                We may, without prior notice, change the Assessment Builder; stop providing the Assessment Builder or
                features of the Assessment Builder, to you or to Users generally; or create usage limits for the
                Assessment Builder. We may permanently or temporarily terminate or suspend your access to the Assessment
                Builder without notice and liability for any reason, including if in our sole determination you violate
                any provision of these Terms, or for no reason. Upon termination for any reason or no reason, you
                continue to be bound by these Terms.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">1.6 Service Location</Heading>
              <Paragraph>
                The Assessment Builder is controlled and operated from facilities in the United States and India.
                Frontera makes no representations that the Assessment Builder is appropriate or available for use in
                other locations. Those who access or use the Assessment Builder from other jurisdictions do so at their
                own volition and are entirely responsible for compliance with all applicable United States and local
                laws and regulations, including but not limited to export and import regulations. You may not use the
                Assessment Builder if you are a resident of a country embargoed by the United States, or are a foreign
                person or entity blocked or denied by the United States government. Unless otherwise explicitly stated,
                all materials found on the Assessment Builder are solely directed to Users located in the United State
                and India.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">1.7 Business Associate Agreement</Heading>
              <Paragraph>
                Each party to these Terms represents and warrants that it will comply with all applicable privacy and
                data protection laws and regulations, including, without limitation, the Health Insurance Portability
                and Accountability Act and regulations promulgated thereunder, as amended from time to time (“HIPAA”)
                and all other federal and state patient privacy laws. To the extent Frontera will create, receive,
                maintain, or transmit Protected Health Information (as defined at 45 CFR § 160.103) (“PHI”) for you or
                on your behalf as a business associate (as defined at 45 CFR § 160.103) in providing the Assessment
                Builder, the business associate agreement attached hereto as Exhibit A is incorporated herein by
                reference.
              </Paragraph>
            </section>
          </section>

          {/* 2. User Content */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="user-content">
              2. User Content
            </Heading>
            <Paragraph>
              Some areas of the Assessment Builder allow Users to submit, post, display, provide, or otherwise make
              available content such as intake call recordings, diagnosis reports, other clinical documents, profile
              information, comments, questions, and other content or information (any such materials a User submits,
              posts, displays, provides, or otherwise makes available on the Assessment Builder is referred to as “User
              Content”).{" "}
            </Paragraph>
            <Paragraph>
              We claim no ownership rights over User Content created by you. The User Content you create remains yours.{" "}
            </Paragraph>
            <Paragraph>
              {" "}
              By submitting, posting, displaying, providing, or otherwise making available any User Content on or
              through the Assessment Builder, you expressly grant, and you represent and warrant that you have all
              rights necessary to grant, to Frontera a royalty-free, sublicensable, transferable, perpetual,
              irrevocable, non-exclusive, worldwide license to use, reproduce, modify, publish, list information
              regarding, edit, translate, distribute, syndicate, publicly perform, publicly display, and make derivative
              works of all such User Content and your name, voice, and/or likeness as contained in your User Content, in
              whole or in part, and in any form, media or technology, whether now known or hereafter developed, for use
              in connection with the Assessment Builder and Frontera’s (and its successors’ and affiliates’) business,
              including without limitation for promoting and redistributing part or all of the Assessment Builder (and
              derivative works thereof) in any media formats and through any media channels.
            </Paragraph>
            <Paragraph>
              {" "}
              For the purposes of these Terms, “Intellectual Property Rights” means all patent rights, copyright rights,
              mask work rights, moral rights, rights of publicity, trademark, trade dress and service mark rights,
              goodwill, trade secret rights and other intellectual property rights as may now exist or hereafter come
              into existence, and all applications therefore and registrations, renewals and extensions thereof, under
              the laws of any state, country, territory or other jurisdiction. In connection with your User Content, you
              affirm, represent and warrant the following:
            </Paragraph>
            <Paragraph>
              In connection with your User Content, you affirm, represent and warrant the following:
            </Paragraph>
            <ul className="list-disc pl-4 space-y-4 marker:text-text-secondary">
              <li>
                <Paragraph>
                  You have the written consent of each and every identifiable natural person in the User Content, if
                  any, to use such person’s name or likeness in the manner contemplated by the Assessment Builder and
                  these Terms.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  You have obtained and are solely responsible for obtaining all consents as may be required by law to
                  post any User Content relating to third parties.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  Your User Content and Frontera’s use thereof as contemplated by these Terms and the Assessment Builder
                  will not violate any law or infringe any rights of any third party, including but not limited to any
                  Intellectual Property Rights and privacy rights.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  Company may exercise the rights to your User Content granted under these Terms without liability for
                  payment of any guild fees, residuals, payments, fees, or royalties payable under any collective
                  bargaining agreement or otherwise.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  To the best of your knowledge, all your User Content and other information that you provide to us is
                  truthful and accurate.
                </Paragraph>
              </li>
            </ul>

            <Paragraph>
              You shall be solely responsible for your User Content and the consequences of posting, publishing it,
              sharing it, or otherwise making it available on the Assessment Builder, and you agree that we are only
              acting as a passive conduit for your online distribution and publication of your User Content. You
              understand and agree that you may be exposed to User Content that is inaccurate, objectionable,
              inappropriate for children, or otherwise unsuited to your purpose, and you agree that Frontera shall not
              be liable for any damages you allege to incur as a result of or relating to any User Content.
            </Paragraph>
          </section>

          {/* 3. Our Proprietary Rights */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="our-proprietary-rights">
              3. Our Proprietary Rights
            </Heading>
            <Paragraph>
              Except for your User Content, the Assessment Builder and all materials therein or transferred thereby,
              including, without limitation, software, images, text, graphics, illustrations, logos, patents,
              trademarks, service marks, copyrights, photographs, audio, videos and music (the “Company Content”), and
              all Intellectual Property Rights related thereto, are the exclusive property of Frontera and its
              licensors. Except as explicitly provided herein, nothing in these Terms shall be deemed to create a
              license in or under any such Intellectual Property Rights, and you agree not to sell, license, rent,
              modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or
              create derivative works from any Frontera Content. Use of the Frontera Content for any purpose not
              expressly permitted by these Terms is strictly prohibited.
            </Paragraph>
            <Paragraph>
              Frontera also reserves the right to collect, maintain, process, and use, or you may provide to Frontera,
              diagnostic, technical, usage, and related information, including information about your computers, mobile
              devices, systems, and software (collectively, “Usage Data”). You agree that all Usage Data is owned solely
              and exclusively by Frontera, and, to the extent any ownership rights in or to the Usage Data vest in you,
              you hereby assign to Frontera all rights, title, and interest in and to the same. Accordingly, Frontera
              may use the Usage Data or any portion thereof for any lawful purpose, including, without limitation: (a)
              to provide and maintain the Assessment Builder; (b) to develop and improve Frontera’s products and
              services, including without limitation artificial intelligence and machine learning models used in
              connection with the Assessment Builder; (c) to monitor your usage of the Assessment Builder; (d) for
              research and analytics and for Frontera’s other business purposes; and (e) to share analytics and other
              derived Usage Data with third parties, solely in deidentified or aggregated form. The Assessment Builder
              may contain technological measures designed to prevent unauthorized or illegal use of the Assessment
              Builder. You acknowledge and agree that Frontera may use these and other lawful measures to verify your
              compliance with the terms of these Terms and to enforce Frontera’s rights, including all Intellectual
              Property Rights, in and to the Assessment Builder.
            </Paragraph>
            <Paragraph>
              You may choose to or we may invite you to submit comments or ideas about the Assessment Builder, including
              without limitation about how to improve the Assessment Builder or our products (“Ideas”). By submitting
              any Idea, you agree that your disclosure is gratuitous, unsolicited and without restriction and will not
              place Frontera under any fiduciary or other obligation, and that we are free to use the Idea without any
              additional compensation to you, and/or to disclose the Idea on a non-confidential basis or otherwise to
              anyone. You further acknowledge that, by acceptance of your submission, Frontera does not waive any rights
              to use similar or related ideas previously known to Frontera, or developed by its employees, or obtained
              from sources other than you.
            </Paragraph>
          </section>

          {/* 4. Paid Services */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="paid-services">
              4. Paid Services
            </Heading>
          </section>

          {/* 4. Paid Services Subsections */}
          <section className={subSectionSpacing}>
            <section className={topSectionSpacing}>
              <Heading type="h3">4.1 Billing Policies</Heading>
              <Paragraph>
                Certain aspects of the Assessment Builder may be provided for a fee or other charge. If you elect to use
                paid aspects of the Assessment Builder, you agree to our Pricing and Payment Terms, as we may update
                them from time to time. Frontera may add new services for additional fees and charges, add or amend fees
                and charges for existing services, at any time in its sole discretion.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">4.2 No Refunds</Heading>
              <Paragraph>
                You may cancel your User Account at any time; however, there are no refunds for cancellation. In the
                event that Frontera suspends or terminates your User Account or these Terms, you understand and agree
                that you shall receive no refund or exchange for any unused time on a subscription, any license or
                subscription fees for any portion of the Assessment Builder, any content or data associated with your
                User Account, or for anything else.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">4.3 Payment Information; Taxes</Heading>
              <Paragraph>
                All information that you provide in connection with a purchase or transaction with the Assessment
                Builder must be accurate, complete, and current. You agree to pay all charges incurred by users of your
                payment method used in connection with a purchase or transaction or other monetary transaction
                interaction with the Assessment Builder at the prices in effect when such charges are incurred. You will
                pay all applicable taxes, if any, relating to any such purchases and transactions.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">4.4 California Residents</Heading>
              <Paragraph>
                The provider of services is Frontera Health, Inc., (720) 334-8428, 1925 Blake Street Suite 200, 80211
                Denver CO,{" "}
                <a
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  href="mailto:support@fronterahealth.com"
                >
                  support@fronterahealth.com
                </a>
                . If you are a California resident, in accordance with Cal. Civ. Code §1789.3, you may report complaints
                to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of
                Consumer Affairs by contacting them in writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA
                95834, or by telephone at (800) 952-5210 or (916) 445-1254.
              </Paragraph>
            </section>
          </section>

          {/* 5. Important Disclosures */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="important-disclosures">
              5. Important Disclosures
            </Heading>
          </section>

          {/* 5. Important Disclosures Subsections */}
          <section className={subSectionSpacing}>
            <section className={topSectionSpacing}>
              <Heading type="h3">5.1 Not for Emergencies</Heading>
              <Paragraph>
                IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD NOT USE THE ASSESSMENT BUILDER. PLEASE DIAL 911
                OR GO TO A HOSPITAL.{" "}
              </Paragraph>
              <Paragraph>
                The Assessment Builder is not for medical emergencies or urgent situations. You should not disregard or
                delay to seek medical advice based on anything on the Assessment Builder. If you believe you have an
                emergency, please call 911 or go to a hospital immediately.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">
                5.2 No Medical or Professional Advice from Frontera; No Doctor-Patient Relationship
              </Heading>
              <Paragraph>
                Although the Assessment Builder may provide access to certain health-related information, Frontera does
                not directly or indirectly offer medical advice or other professional advice, and your use of the
                Assessment Builder does not create a doctor-patient, provider-patient or similar relationship between
                you and Frontera. Any information on or which may be discovered through the Assessment Builder is
                intended to be general information in regard to the subject matter covered, and any such information
                should not be considered complete, nor should it be relied on to suggest a basis for diagnosis or course
                of treatment. ANY ADVICE OR INFORMATION GENERATED BY THE ASSESSMENT BIULDER SHOULD BE REVIEWED CAREFULLY
                BY A QUALIFIED PROFESSIONAL.
              </Paragraph>
            </section>
          </section>

          {/* 6. Text Messaging */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="text-messaging">
              6. Text Messaging
            </Heading>
            <Paragraph>
              You expressly consent and agree that Frontera can contact you using written, electronic, or verbal means,
              including by manual dialing, emails, leaving prerecorded/artificial voice messages or using an automatic
              telephone dialing system to call or text your mobile/cellular telephone number, as necessary to complete
              transactions requested by you and to service your account and as the law allows, even if those phone
              numbers are registered by you on any federal or state Do-Not-Call/Do-Not-email registry.{" "}
            </Paragraph>
            <Paragraph>
              {" "}
              We offer you the chance to enroll to receive recurring SMS/text messages from Frontera. You may enroll to
              receive text messages about account-related news and alerts and/or marketing and promotional offers for
              Frontera products and services. By enrolling in Frontera’s SMS/text messaging service, you agree to
              receive text messages from Frontera to your mobile phone number provided, and you certify that your mobile
              number provided is true and accurate and that you are authorized to enroll the designated mobile number to
              receive such texts. You acknowledge and agree that the texts may be sent using an automatic telephone
              dialing system and that standard message and data rates apply. Consent is not required as a condition of
              purchase. Frontera is not responsible for any delays upon sending or receiving text messages.
            </Paragraph>
          </section>

          {/* 7. Security */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="security">
              7. Security
            </Heading>
            <Paragraph>
              Company cares about the integrity and security of your personal information. However, we cannot guarantee
              that unauthorized third parties will never be able to defeat our security measures or use your personal
              information for improper purposes. You acknowledge that you provide your personal information at your own
              risk.
            </Paragraph>
          </section>

          {/* 8. Third-Party Links and Information */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="third-party-links-and-information">
              8. Third-Party Links and Information
            </Heading>
            <Paragraph>
              The Assessment Builder may contain links to third-party materials that are not owned or controlled by
              Frontera. Frontera does not endorse or assume any responsibility for any such third-party sites,
              information, materials, products, or services. If you access a third-party website or service from the
              Assessment Builder or share your User Content on or through any third-party website or service, you do so
              at your own risk, and you understand that these Terms and Frontera’s Privacy Policy do not apply to your
              use of such sites. You expressly relieve Frontera from any and all liability arising from your use of any
              third-party website, service, or content.
            </Paragraph>
          </section>

          {/* 9. Indemnity */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="indemnity">
              9. Indemnity
            </Heading>
            <Paragraph>
              You hereby release Frontera from all damages (whether direct, indirect, incidental, consequential, or
              otherwise), losses, liabilities, costs, and expenses of every kind and nature, known and unknown, arising
              out of a dispute between you and a third-party in connection with the Assessment Builder. In addition, you
              waive any applicable law or statute, which says, in substance: “A GENERAL RELEASE DOES NOT EXTEND TO
              CLAIMS WHICH THE RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
              EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT
              WITH THE RELEASED PARTY.”
            </Paragraph>
            <Paragraph>
              You agree to defend, indemnify and hold harmless Frontera and its subsidiaries, agents, licensors,
              managers, and other affiliated companies, and their employees, contractors, agents, officers and
              directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt,
              and expenses (including but not limited to attorney’s fees) arising from: (i) your use of and access to
              the Assessment Builder, including any data or content transmitted or received by you; (ii) your violation
              of any term of these Terms, including without limitation your breach of any of the representations and
              warranties above; (iii) your violation of any third-party right, including without limitation any right of
              privacy or Intellectual Property Rights; (iv) your violation of any applicable law, rule or regulation;
              (v) User Content or any content that is submitted via your User Account including without limitation
              misleading, false, or inaccurate information; (vi) your willful misconduct; or (vii) any other party’s
              access and use of the Assessment Builder with your unique username, password or other appropriate security
              code.
            </Paragraph>
          </section>

          {/* 10. No Warranty */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="no-warranty">
              10. No Warranty
            </Heading>
            <Paragraph>
              The Assessment Builder is provided on an “as is” and “as available” basis. Use of the Assessment Builder
              is at your own risk. To the maximum extent permitted by applicable law, the Assessment Builder is provided
              without warranties of any kind, whether express or implied, including, but not limited to, implied
              warranties of merchantability, fitness for a particular purpose, or non-infringement. No advice or
              information, whether oral or written, obtained by you from Frontera or through the Assessment Builder will
              create any warranty not expressly stated herein. Without limiting the foregoing, Frontera, its
              subsidiaries, its affiliates, and its licensors do not warrant that the content is accurate, reliable or
              correct; that the Assessment Builder will meet your requirements; that the Assessment Builder will be
              available at any particular time or location, uninterrupted or secure; that any defects or errors will be
              corrected; or that the Assessment Builder is free of viruses or other harmful components. Any content
              downloaded or otherwise obtained through the use of the Assessment Builder is downloaded at your own risk
              and you will be solely responsible for any damage to your computer system or mobile device or loss of data
              that results from such download or your use of the Assessment Builder.
            </Paragraph>
            <Paragraph>
              Further, Frontera does not warrant, endorse, guarantee, or assume responsibility for any product or
              service advertised or offered by a third party through the Assessment Builder or any hyperlinked website
              or service, and Frontera will not be a party to or in any way monitor any transaction between you and
              third-party providers of products or services.
            </Paragraph>
            <Paragraph>
              Federal law, some states, provinces and other jurisdictions do not allow the exclusion and limitations of
              certain implied warranties, so the above exclusions may not apply to you. These Terms give you specific
              legal rights, and you may also have other rights which vary from state to state. The disclaimers and
              exclusions under these Terms will not apply to the extent prohibited by applicable law.
            </Paragraph>
          </section>

          {/* 11. Limitation of Liability */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="limitation-of-liability">
              11. Limitation of Liability
            </Heading>
            <Paragraph>
              To the maximum extent permitted by applicable law, in no event shall Frontera, its affiliates, agents,
              directors, employees, suppliers or licensors be liable for any indirect, punitive, incidental, special,
              consequential or exemplary damages, including without limitation damages for loss of profits, goodwill,
              use, data or other intangible losses, arising out of or relating to the use of, or inability to use, the
              Assessment Builder. Under no circumstances will Frontera be responsible for any damage, loss or injury
              resulting from hacking, tampering or other unauthorized access or use of the Assessment Builder or your
              account or the information contained therein.
            </Paragraph>
            <Paragraph>
              To the maximum extent permitted by applicable law, Frontera assumes no liability or responsibility for any
              (i) errors, mistakes, or inaccuracies of content; (ii) personal injury or property damage, of any nature
              whatsoever, resulting from your access to or use of the Assessment Builder; (iii) any unauthorized access
              to or use of our secure servers and/or any and all personal information stored therein; (iv) any
              interruption or cessation of transmission to or from the Assessment Builder; (v) any bugs, viruses, trojan
              horses, or the like that may be transmitted to or through the Assessment Builder by any third party; (vi)
              any errors or omissions in any content or for any loss or damage incurred as a result of the use of any
              content posted, emailed, transmitted, or otherwise made available through the Assessment Builder; and/or
              (vii) User Content or the defamatory, offensive, or illegal conduct of any third party. In no event shall
              Frontera, its affiliates, agents, directors, employees, suppliers, or licensors be liable to you for any
              claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the amount
              you paid to Frontera hereunder or $50.00, whichever is greater.
            </Paragraph>
            <Paragraph>
              This limitation of liability section applies whether the alleged liability is based on contract, tort,
              negligence, strict liability, or any other basis, even if Frontera has been advised of the possibility of
              such damage.
            </Paragraph>
            <Paragraph>
              Some states do not allow the exclusion or limitation of incidental or consequential damages, so the above
              limitations or exclusions may not apply to you. These Terms give you specific legal rights, and you may
              also have other rights which vary from state to state. The disclaimers, exclusions, and limitations of
              liability under these Terms will not apply to the extent prohibited by applicable law.
            </Paragraph>
          </section>

          {/* 12. Governing Law, Arbitration, and Class Action / Jury Trial Waiver*/}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="governing-law-arbitration-and-class-action-jury-trial-waiver">
              12. Governing Law, Arbitration, and Class Action / Jury Trial Waiver
            </Heading>
          </section>

          {/* 12. Governing Law, Arbitration, and Class Action / Jury Trial Waiver Sub Sections*/}
          <section className={subSectionSpacing}>
            <section className={topSectionSpacing}>
              <Heading type="h3">12.1 Governing Law</Heading>
              <Paragraph>
                You agree that: (i) the Assessment Builder shall be deemed solely based in Delaware; and (ii) the
                Assessment Builder shall be deemed a passive one that does not give rise to personal jurisdiction over
                us, either specific or general, in jurisdictions other than Delaware. These Terms shall be governed by
                the internal substantive laws of the State of Delaware, without respect to its conflict of laws
                principles. The parties acknowledge that these Terms evidence a transaction involving interstate
                commerce. Notwithstanding the preceding sentences with respect to the substantive law, the Federal
                Arbitration Act (9 U.S.C. §§ 1-16) (“FAA”) governs the interpretation and enforcement of the Arbitration
                Agreement in Section 12.2 and preempts all state laws to the fullest extent permitted by law. If the FAA
                is found to not apply to any issue that arises from or relates to the Arbitration Agreement, then that
                issue shall be resolved under and governed by the law of your state of residence. The application of the
                United Nations Convention on Contracts for the International Sale of Goods is expressly excluded. You
                agree to submit to the exclusive personal jurisdiction of the federal and state courts located in
                Delaware for any actions for which we retain the right to seek injunctive or other equitable relief in a
                court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or
                violation of a our copyrights, trademarks, trade secrets, patents, or other intellectual property or
                proprietary rights, as set forth in the Arbitration provision below, including any provisional relief
                required to prevent irreparable harm. You agree that Delaware is the proper and exclusive forum for any
                appeals of an arbitration award or for trial court proceedings in the event that the arbitration
                provision below is found to be unenforceable.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">12.2 Arbitration</Heading>
              <Paragraph>
                READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO ARBITRATE THEIR DISPUTES AND LIMITS THE
                MANNER IN WHICH YOU CAN SEEK RELIEF FROM FRONTERA. This Section 12.2 (the “Arbitration Agreement”)
                applies to and governs any dispute, controversy, or claim between you and Frontera that arises out of or
                relates to, directly or indirectly: (a) these Terms, including the formation, existence, breach,
                termination, enforcement, interpretation, validity, or enforceability thereof; (b) access to or use of
                the Assessment Builder, including receipt of any advertising or marketing communications; (c) any
                transactions through, by, or using the Assessment Builder; or (d) any other aspect of your relationship
                or transactions with Frontera, directly or indirectly, as a consumer (“Claim” or collectively,
                “Claims”). The Arbitration Agreement shall apply, without limitation, to all Claims that arose or were
                asserted before or after your agreement to these Terms.
              </Paragraph>
              <Paragraph>
                If you are a new Frontera user, you can reject and opt-out of this Arbitration Agreement within 30 days
                of accepting these Terms by emailing Frontera at{" "}
                <a
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  href="mailto:support@fronterahealth.com"
                >
                  support@fronterahealth.com
                </a>{" "}
                with your first and last name and stating your intent to opt-out of the Arbitration Agreement. Note that
                opting out of this Arbitration Agreement does not affect any other part of these Terms, including the
                provisions regarding controlling law or in which courts any disputes must be brought.
              </Paragraph>
              <Paragraph>
                For any Claim, you agree to first contact us at{" "}
                <a
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  href="mailto:support@fronterahealth.com"
                >
                  support@fronterahealth.com
                </a>{" "}
                and attempt to resolve the dispute with us informally. In the unlikely event that Frontera has not been
                able to resolve a Claim after sixty (60) days, we each agree to resolve any Claim exclusively through
                binding arbitration by AAA before a single arbitrator (the “Arbitrator”), under the Expedited Procedures
                then in effect for AAA (the “Rules”), except as provided herein. In the event of any conflict between
                the Rules and this Arbitration Agreement, this Arbitration Agreement shall control. AAA may be contacted
                at www.adr.org, where the Rules are also available. The arbitration will be conducted in the U.S. county
                where you live or Delaware, unless you and Frontera agree otherwise. If you are using the Assessment
                Builder for commercial purposes, each party will be responsible for paying any AAA filing,
                administrative and arbitrator fees in accordance with AAA rules, and the award rendered by the
                arbitrator shall include costs of arbitration, reasonable attorneys’ fees and reasonable costs for
                expert and other witnesses. If you are an individual using the Assessment Builder for non-commercial
                purposes: (i) AAA may require you to pay a fee for the initiation of your case, unless you apply for and
                successfully obtain a fee waiver from AAA; (ii) the award rendered by the arbitrator may include your
                costs of arbitration, your reasonable attorney’s fees, and your reasonable costs for expert and other
                witnesses; and (iii) you may sue in a small claims court of competent jurisdiction without first
                engaging in arbitration, but this does not absolve you of your commitment to engage in the informal
                dispute resolution process. Any judgment on the award rendered by the arbitrator may be entered in any
                court of competent jurisdiction. You and Frontera agree that the Arbitrator, and not any federal, state,
                or local court or agency, shall have exclusive authority to resolve any disputes relating to the
                interpretation, applicability, enforceability or formation of this Arbitration Agreement, including any
                claim that all or any part of this Arbitration Agreement is void or voidable. The Arbitrator shall also
                be responsible for determining all threshold arbitrability issues, including issues relating to whether
                these Terms, any provision of these Terms, is unconscionable or illusory and any defense to arbitration,
                including waiver, delay, laches, unconscionability, or estoppel.
              </Paragraph>
              <Paragraph>
                Nothing in this Section shall be deemed as: preventing Frontera from seeking injunctive or other
                equitable relief from the courts as necessary to prevent the actual or threatened infringement,
                misappropriation, or violation of our data security, Intellectual Property Rights or other proprietary
                rights; or preventing you from asserting claims in small claims court, if your claims qualify and so
                long as the matter remains in such court and advances on only an individual (non-class,
                non-representative) basis.
              </Paragraph>
              <Paragraph>
                If this Arbitration Agreement is found to be void, unenforceable, or unlawful, in whole or in part, the
                void, unenforceable, or unlawful provision, in whole or in part, shall be severed. Severance of the
                void, unenforceable, or unlawful provision, in whole or in part, shall have no impact on the remaining
                provisions of the Arbitration Agreement, which shall remain in force, or the parties’ ability to compel
                arbitration of any remaining claims on an individual basis pursuant to the Arbitration Agreement.
                Notwithstanding the foregoing, if the Class Action/Jury Trial Waiver is found to be void, unenforceable,
                or unlawful, in whole or in part, because it would prevent you from seeking public injunctive relief,
                then any dispute regarding the entitlement to such relief (and only that relief) must be severed from
                arbitration and may be litigated in a civil court of competent jurisdiction. All other claims for relief
                subject to arbitration under this Arbitration Agreement shall be arbitrated under its terms, and the
                parties agree that litigation of any dispute regarding the entitlement to public injunctive relief shall
                be stayed pending the outcome of any individual claims in arbitration.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">12.3 Class Action / Jury Trial Waiver</Heading>
              <Paragraph>
                WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY HAVE OBTAINED OR USED THE
                ASSESSMENT BUILDER FOR PERSONAL, COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE
                PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION,
                COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER
                APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE
                THAN ONE PERSON’S CLAIMS. YOU AND FRONTERA AGREE THAT THE ARBITRATOR MAY AWARD RELIEF ONLY TO AN
                INDIVIDUAL CLAIMANT AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF ON YOUR INDIVIDUAL CLAIM(S). ANY
                RELIEF AWARDED MAY NOT AFFECT OTHER FRONTERA USERS. YOU AND FRONTERA FURTHER AGREE THAT, BY ENTERING
                INTO THESE TERMS, YOU AND FRONTERA ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO BRING, JOIN, OR
                PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER
                REPRESENTATIVE PROCEEDING OF ANY KIND AS A PLAINTIFF OR CLASS MEMBER.
              </Paragraph>
            </section>
          </section>

          {/* 13. General */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header type="h2" id="general">
              13. General
            </Heading>
          </section>

          {/* 12. General Sub Sections*/}
          <section className={subSectionSpacing}>
            <section className={topSectionSpacing}>
              <Heading type="h3">13.1 Assignment</Heading>
              <Paragraph>
                These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you,
                but may be assigned by Frontera without restriction. Any attempted transfer or assignment in violation
                hereof shall be null and void.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">13.2 Notification Procedures and Changes to these Terms</Heading>
              <Paragraph>
                Frontera may provide notifications, whether such notifications are required by law or are for marketing
                or other business related purposes, to you via email notice, written or hard copy notice, or through
                posting of such notice on our website, as determined by Frontera in our sole discretion. Frontera
                reserves the right to determine the form and means of providing notifications to our Users, provided
                that you may opt out of certain means of notification as described in these Terms. Frontera is not
                responsible for any automatic filtering you or your network provider may apply to email notifications we
                send to the email address you provide us. Frontera may, in its sole discretion, modify or update these
                Terms from time to time, and so you should review this page periodically. When we change these Terms in
                a material manner, we will update the ‘last modified’ date at the top of this page and notify you that
                material changes have been made to these Terms. Your continued use of the Assessment Builder after any
                such change constitutes your acceptance of the new Terms of Service. If you do not agree to any of these
                terms or any future Terms of Service, do not use or access (or continue to access) the Assessment
                Builder.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">13.3 Entire Agreement / Severability </Heading>
              <Paragraph>
                These Terms, together with any amendments and any additional agreements you may enter into with Frontera
                in connection with the Assessment Builder, shall constitute the entire agreement between you and
                Frontera concerning the Assessment Builder. Except as otherwise stated in Section 12.2, if any provision
                of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision
                shall not affect the validity of the remaining provisions of these Terms, which shall remain in full
                force and effect.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">13.4 No Waiver</Heading>
              <Paragraph>
                No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any
                other term, and Frontera’s failure to assert any right or provision under these Terms shall not
                constitute a waiver of such right or provision.
              </Paragraph>
            </section>
            <section className={topSectionSpacing}>
              <Heading type="h3">13.5 Contact</Heading>
              <Paragraph>
                Please contact us at{" "}
                <a
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  href="mailto:support@fronterahealth.com"
                >
                  support@fronterahealth.com
                </a>{" "}
                with any questions regarding these Terms.
              </Paragraph>
            </section>
          </section>
        </div>
      </div>
    </div>
  );
};
