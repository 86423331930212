import { copy } from "@sections/copy/copy";

import { Collapsible, Heading, Paragraph } from "@fronterahealth/frontera-ui-components";

import { AnimatedSection } from "@components/AnimatedSection";
import useMediaQuery from "@hooks/useMediaQuery";

interface FAQ {
  question: string;
  answer: string;
}

export const FAQ: React.FC = () => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
    <div
      className={`flex flex-col ${isDesktop ? "px-36" : "px-4"} pt-12 pb-36 justify-start items-center bg-limestone-50 w-full`}
    >
      <AnimatedSection>
        <div className="max-w-screen-xl flex items-center flex-col justify-center text-center w-full">
          <div className="flex flex-col gap-y-4 my-8 items-center text-center">
            <Heading id="faq" type="h1">
              {copy.faq.title}
            </Heading>
            <Paragraph className="max-w-screen-sm">
              {copy.faq.subtitleCopy} {isDesktop ? null : <br />}
              <a href={copy.faq.subtitleCtaHref}>
                <span className="text-green-700 hover:text-green-900">{copy.faq.subtitleCta}</span>
              </a>
            </Paragraph>
          </div>

          <div className="flex flex-col items-start w-full divide-y divide-limestone-100">
            {copy.faq.questions.map(({ question, answer }) => {
              return (
                <Collapsible
                  headerIsClickable
                  key={question}
                  headerContent={
                    <div className="mt-8 text-left pr-12">
                      <Heading type="h3">{question}</Heading>
                    </div>
                  }
                  bodyContent={
                    <div className="text-left mt-2">
                      <Paragraph>{answer}</Paragraph>
                    </div>
                  }
                />
              );
            })}
          </div>
        </div>
      </AnimatedSection>
    </div>
  );
};
