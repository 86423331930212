import { useAuth0 } from "@auth0/auth0-react";
import { REFERRAL_QUERY_PARAMETER_KEY, TRY_FOR_FREE_REFERRAL_VALUE } from "@constants/constants";
import hippaSvg from "@sections/Splash/hipaa.svg";
import underlineSvg from "@sections/Splash/underline.svg";
import { copy } from "@sections/copy/copy";
import React from "react";

import { Button, Heading, JumboHeading, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { AnimatedSection } from "@components/AnimatedSection";
import useMediaQuery from "@hooks/useMediaQuery";

export const Splash: React.FC = () => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
    <div
      id="splash-section"
      className={`flex flex-col  bg-limestone-50 ${isDesktop ? "" : ""}   ${isDesktop ? "pt-36 px-28" : "py-4 px-8"} justify-start items-center ${isDesktop ? "pb-[80px]" : "pb-[80px]"}`}
    >
      <div className="max-w-screen-xl flex items-center flex-col justify-center text-center">
        <div className="flex flex-col items-center">
          <AnimatedSection>
            <div className="flex flex-col items-center">
              {isDesktop ? (
                <JumboHeading className="relative overflow-visible font-bold text-text-primary">
                  Transforming Assessment Reports <br />
                  for{" "}
                  <span className="relative">
                    Better
                    <object
                      type="image/svg+xml"
                      data={underlineSvg}
                      className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 mt-1"
                    />
                  </span>{" "}
                  Clinical Outcomes
                </JumboHeading>
              ) : (
                <Heading type="h1" className="text-[40px] leading-[48px]">
                  Transforming Assessment Reports for{" "}
                  <span className="relative">
                    Better
                    <object
                      style={{ maxWidth: "100%" }}
                      type="image/svg+xml"
                      data={underlineSvg}
                      className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 mt-1"
                    />
                  </span>{" "}
                  Clinical Outcomes
                </Heading>
              )}
            </div>
          </AnimatedSection>
          <AnimatedSection delay={0.2}>
            <Paragraph className="mt-8 w-full max-w-2xl">{copy.splash.subheading}</Paragraph>
          </AnimatedSection>
        </div>

        <AnimatedSection delay={0.4} spanClassName="flex flex-col items-center">
          {user && isAuthenticated ? (
            <div className="flex items-center gap-x-4 my-8">
              <Small>Welcome, {user.name}</Small>
              <a href={import.meta.env.VITE_PORTAL_URL} target="_blank">
                <Button text={"Go To Assessment Builder"} appearance={"primary"} className="bg-hatch-700" />
              </a>
            </div>
          ) : (
            <div className="flex items-center my-8 gap-x-4">
              <Button
                text={"Try for Free"}
                appearance={"primary"}
                onClick={() => {
                  const returnTo = `${import.meta.env.VITE_REDIRECT_URL}?${REFERRAL_QUERY_PARAMETER_KEY}=${TRY_FOR_FREE_REFERRAL_VALUE}`;
                  console.debug("<> Redirecting, here is the returnTo ", { returnTo });
                  loginWithRedirect({
                    authorizationParams: {
                      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                      redirect_uri: window.location.href,
                      screen_hint: "signup",
                    },
                    appState: {
                      returnTo,
                    },
                  });
                }}
              />
              <a href="#pricing">
                <Button text={copy.splash.explorePricingButton} appearance={"secondary"} className="bg-transparent" />
              </a>
            </div>
          )}
          <img src={hippaSvg} />
        </AnimatedSection>
      </div>
    </div>
  );
};
