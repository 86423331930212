import { useInView } from "framer-motion";
import { PropsWithChildren, useRef } from "react";
import { twMerge } from "tailwind-merge";

interface AnimatedSectionProps extends PropsWithChildren {
  delay?: number;
  sectionClassName?: string;
  spanClassName?: string;
  percentageInViewBeforeTriggering?: number;
}
export const AnimatedSection: React.FC<AnimatedSectionProps> = ({
  children,
  delay = 0,
  sectionClassName,
  spanClassName,
  percentageInViewBeforeTriggering = 0.5,
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: percentageInViewBeforeTriggering, once: true });

  return (
    <section
      ref={ref}
      className={twMerge("flex justify-start overflow-visible w-full flex-col items-center", sectionClassName)}
    >
      <span
        className={twMerge("translate-y-[-100px] w-full flex flex-col items-center", spanClassName)}
        style={{
          transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay}s`,
        }}
      >
        {children}
      </span>
    </section>
  );
};
