import { datadogRum } from "@datadog/browser-rum";
import React from "react";
import ReactDOM from "react-dom/client";

import { FronteraAuthProvider } from "@fronterahealth/frontera-auth0-react";

import App from "./App";
import { BAA } from "./BAA";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TermsOfUse } from "./TermsOfUse";
import "./index.css";

datadogRum.init({
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: "us3.datadoghq.com",
  service: "frontera-assessments-website-ui",
  env: "prod",
  version: import.meta.env.VITE_COMMIT_HASH || "<missing-version>",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

const getCookieDomainFromHostname = (
  hostname: string,
):
  | ".dev.fronterahealth.com"
  | ".staging.fronterahealth.com"
  | ".fronterahealth.com"
  | ".local.fronterahealth.com"
  | ".unknown-cookie.fronterahealth.com" => {
  switch (hostname) {
    case "assessments.dev.fronterahealth.com":
      return ".dev.fronterahealth.com";
    case "assessments.staging.fronterahealth.com":
      return ".staging.fronterahealth.com";
    case "assessments.fronterahealth.com":
    case "fronterahealth.com":
      return ".fronterahealth.com";
    case "localhost":
      return ".local.fronterahealth.com";
    default:
      console.error("Unexpected hostname, this should not happen");
      return ".unknown-cookie.fronterahealth.com";
  }
};

export const COOKIE_DOMAIN = getCookieDomainFromHostname(window.location.hostname);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <FronteraAuthProvider
      loginOnMount={false}
      cookieDomain={COOKIE_DOMAIN}
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      audience={import.meta.env.VITE_AUTH0_AUDIENCE}
      onRedirectCallback={(appState) => {
        console.debug("<> onRedirectCallback (inside Website)", { appState });
        window.location.href = appState?.returnTo || window.location.href;
      }}
    >
      <TermsOfUse />
      <BAA />
      <PrivacyPolicy />
      <App />
    </FronteraAuthProvider>
  </React.StrictMode>,
);
