import { ArrowUpOnSquareIcon, ClipboardIcon, PencilIcon, SparklesIcon } from "@heroicons/react/24/solid";
import dataCollectionImage from "@sections/HowItWorks/01-data-collection-image.png";
import aiAnalysisImage from "@sections/HowItWorks/02-ai-analysis-image.png";
import treatmentPlanGenerationImage from "@sections/HowItWorks/03-treatment-plan-generation-image.png";
import reviewAndEditImage from "@sections/HowItWorks/04-review-and-edit-image.png";
import exportAndShareImage from "@sections/HowItWorks/05-export-and-share.png";
import { copy } from "@sections/copy/copy";
import { useEffect, useState } from "react";

import { Heading, Paragraph } from "@fronterahealth/frontera-ui-components";

import useMediaQuery from "@hooks/useMediaQuery";

export type HeroIconType = React.ForwardRefExoticComponent<
  Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
    title?: string | undefined;
    titleId?: string | undefined;
  } & React.RefAttributes<SVGSVGElement>
>;

interface FeatureInterface {
  title: string;
  subtitle: string;
  icon: HeroIconType;
  image: string;
}

const Features: FeatureInterface[] = [
  {
    title: copy.howItWorks.steps.one.title,
    subtitle: copy.howItWorks.steps.one.subtitle,
    icon: ArrowUpOnSquareIcon,
    image: dataCollectionImage,
  },
  {
    title: copy.howItWorks.steps.two.title,
    subtitle: copy.howItWorks.steps.two.subtitle,
    icon: SparklesIcon,
    image: aiAnalysisImage,
  },
  {
    title: copy.howItWorks.steps.three.title,
    subtitle: copy.howItWorks.steps.three.subtitle,
    icon: ClipboardIcon,
    image: treatmentPlanGenerationImage,
  },
  {
    title: copy.howItWorks.steps.four.title,
    subtitle: copy.howItWorks.steps.four.subtitle,
    icon: PencilIcon,
    image: reviewAndEditImage,
  },
  {
    title: copy.howItWorks.steps.five.title,
    subtitle: copy.howItWorks.steps.five.subtitle,
    icon: PencilIcon,
    image: exportAndShareImage,
  },
];

interface FeatureSelectorProps {
  selectedFeature: string;
  setSelectedFeature: (feature: string) => void;
}
export const FeatureSelector: React.FC<FeatureSelectorProps> = ({ selectedFeature, setSelectedFeature }) => {
  return (
    <div className="flex flex-col ">
      {Features.map((feature, index) => {
        return (
          <div
            onClick={() => setSelectedFeature(feature.title)}
            className={`${selectedFeature === feature.title ? "bg-limestone-50" : "bg-white hover:bg-limestone-50"} flex items-start p-6  cursor-pointer text-left border-limestone-100 border-b`}
            key={feature.title}
          >
            <Paragraph colorType="secondary">0{index + 1}.</Paragraph>
            <div className="flex flex-col items-start ml-6">
              <Heading type="h3">{feature.title}</Heading>
              {selectedFeature === feature.title ? (
                <Paragraph className="min-h-[48px]">{feature.subtitle}</Paragraph>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface FeaturePreviewProps {
  selectedFeature: string;
}
const FeaturePreview: React.FC<FeaturePreviewProps> = ({ selectedFeature }) => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const _feature = Features.find((f) => f.title === selectedFeature);
  return (
    <div className="flex items-center justify-center">
      <img className={`${isDesktop ? "max-h-[500px] max-w-[585px]" : "max-h-[315px]"}`} src={_feature?.image} />
    </div>
  );
};

export const HowItWorks: React.FC = () => {
  const isDesktop = useMediaQuery("(min-width: 960px)");

  const [selectedFeature, setSelectedFeature] = useState<string>(Features[0].title);

  useEffect(() => {}, []);
  return (
    <div className={`flex flex-col ${isDesktop ? "px-20" : "px-4"} py-12 justify-start items-center`}>
      <div className="max-w-screen-xl flex items-center flex-col justify-center text-center">
        <div className="flex flex-col ">
          <Heading type="h1">{copy.howItWorks.title}</Heading>
          <Paragraph className="max-w-screen-sm">{copy.howItWorks.subtitle}</Paragraph>
        </div>
        <div className={`${isDesktop ? "grid grid-cols-2 mt-16 gap-x-4 items-center" : "mt-6 flex flex-col gap-y-4"}`}>
          <FeaturePreview selectedFeature={selectedFeature} />
          <FeatureSelector selectedFeature={selectedFeature} setSelectedFeature={setSelectedFeature} />
        </div>
      </div>
    </div>
  );
};
