import { Heading, Paragraph, Small } from "@fronterahealth/frontera-ui-components";
import logo from "@fronterahealth/frontera-ui-components/assets/logo.png";

const topSectionSpacing = "space-y-4 mb-6 flex flex-col items-start";

export const BAA = () => {
  if (window.location.pathname !== "/baa") return null;
  return (
    <div className="relative">
      <div className="terms-of-use-container p-4 max-w-screen-lg mx-auto gap-y-4 flex flex-col items-center py-20 relative">
        <div className="my-4 flex max-w-[192px] h-[40px] max-h-[40px]  items-center">
          <img className="max-h-[32px]" src={logo} alt="" />
        </div>
        <Heading type="h1">Business Associate Agreement</Heading>
        <Small>Last Revised: September 03, 2024</Small>

        <div className="flex flex-col items-start">
          {/* Introduction */}
          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="introduction" type="h2">
              Introduction
            </Heading>
            <Paragraph>
              This Business Associate Agreement (“Agreement”) is entered into by and between Frontera Health, Inc., a
              Delaware corporation (“Business Associate”) and you (“Covered Entity”), a covered entity under the Health
              Insurance Portability and Accountability Act of 1996 (“HIPAA”). The parties are entering into this
              Agreement to assist the Covered Entity in complying with HIPAA, and to set forth Business Associate’s
              obligations under the Health Information Technology for Economic and Clinical Health Act of 2009 (the
              “HITECH Act”), and 45 CFR Parts 160 and 164, Subpart C (the “Security Rule”), Subpart D (the “Data Breach
              Notification Rule”), and Subpart E (the “Privacy Rule”) (collectively, the “HIPAA Regulations”) and
              applicable State law. Terms used in this Agreement have the meanings given them in the HIPAA Regulations.
              This Agreement applies to any Protected Health Information Business Associate receives from Covered
              Entity, or creates, receives or maintains on behalf of Covered Entity, under its agreements with Covered
              Entity (the “Principal Agreements”).
            </Paragraph>
          </section>

          <section className={topSectionSpacing}>
            <Heading data-sticky-nav-header id="agreement" type="h2">
              Agreement
            </Heading>
            <ol className="list-decimal pl-6 space-y-4 marker:text-text-secondary">
              <li>
                <Paragraph>
                  Business Associate may use and disclose Covered Entity’s Protected Health Information to provide
                  Covered Entity with the goods and services contemplated by the Principal Agreements. Except as
                  expressly provided below, this Agreement does not authorize Business Associate to make any use or
                  disclosure of Protected Health Information that Covered Entity would not be permitted to make.
                </Paragraph>
              </li>
              <li>
                <Paragraph>Business Associate Will:</Paragraph>
                <ol type="a" className="list-[revert-layer] pl-6 space-y-4 marker:text-text-secondary">
                  <li>
                    <Paragraph>
                      Not use or further disclose Covered Entity’s Protected Health Information except as permitted by
                      this Agreement, or as required by law;
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Use appropriate safeguards, and comply, where applicable, with the HIPAA Security Rule with
                      respect to electronic protected health information, to prevent use or disclosure of Covered
                      Entity’s Protected Health Information other than as provided for by this Agreement;
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Report to Covered Entity any use or disclosure of Covered Entity’s Protected Health Information
                      not provided for by this Agreement of which it becomes aware, including breaches of unsecured
                      protected health information as required by the Data Breach Notification Rule (45 CFR § 164.410),
                      and any security incident of which Business Associate becomes aware. Notwithstanding the
                      foregoing, Covered Entity and Business Associate hereby agree that Business Associate receives
                      frequent, routine, unsuccessful attempts to penetrate or compromise its systems, including pings,
                      port scans and log on attempts, and that this constitutes Business Associate’s report and
                      notification to Covered Entity of such events, and no further reporting of such Security Incidents
                      is required unless these attempts result in an unauthorized access to, use, disclosure,
                      destruction or loss of electronic Protected Health Information.
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Ensure that any of Business Associate’s subcontractors that create, receive, maintain, or transmit
                      protected health information on behalf of the Business Associate agree in writing to the same
                      restrictions and conditions that apply to Business Associate with respect to such information,
                      including compliance with the HIPAA Security Rule with respect to electronic protected health
                      information;
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Make any Protected Health Information in a designated record set available to Covered Entity to
                      enable Covered Entity to meet its obligation to provide access to the information in accordance
                      with 45 CFR § 164.524;
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Make any Protected Health Information in a designated record set available for amendment and
                      incorporate any amendments to Protected Health Information as directed by Covered Entity pursuant
                      to 45 CFR § 164.526;
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Make available to Covered Entity the information concerning disclosures that Business Associate
                      makes of Covered Entity’s Protected Health Information required to enable Covered Entity to
                      provide an accounting of disclosures in accordance with 45 CFR § 164.528;
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      To the extent that Business Associate carries out Covered Entity’s obligations under the Privacy
                      Rule, comply with the requirements of the Privacy Rule that apply to Covered Entity in the
                      performance of such obligations;
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Make Business Associate’s internal practices, books, and records relating to Business Associate’s
                      use and disclosure of Protected Health Information received from Covered Entity, or created or
                      received by Business Associate on behalf of Covered Entity, available to the Secretary of the
                      United States Department of Health and Human Services for purposes of determining Covered Entity’s
                      or Business Associate’s compliance with the HIPAA Regulations;
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Upon termination of the Principal Agreements, return or destroy all Covered Entity’s Protected
                      Health Information that Business Associate still maintains in any form and retain no copies of
                      such information or, if return or destruction is not feasible, extend the protections of this
                      Agreement to that information and limit further use and disclosure to those purposes that make the
                      return or destruction of the information infeasible.
                    </Paragraph>
                  </li>
                </ol>
              </li>
              <li>
                <Paragraph>
                  If Covered Entity determines that Business Associate has violated a material term of this Agreement,
                  and if Business Associate fails to cure such violation within 30 days of delivery of written notice
                  thereof, Covered Entity may immediately terminate this Agreement.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  To the extent permitted by laws applicable to Covered Entity, Business Associate may use Covered
                  Entity’s Protected Health Information for the management and administration of Business Associate’s
                  company and to carry out Business Associate’s own legal responsibilities, and Business Associate may
                  disclose the information for these purposes if Business Associate is required to do so by law, or if
                  Business Associate obtains reasonable assurances from the recipient of the information (1) that it
                  will be held confidentially, and used or further disclosed only as required by law or for the purpose
                  for which it was disclosed to the recipient, and (2) that the recipient will notify Business Associate
                  of any instances of which the recipient is aware in which the confidentiality of the information is
                  breached.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  Business Associate may use Covered Entity’s Protected Health Information for data aggregation, as
                  permitted by the Privacy Rule.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  Business Associate may de-identity Covered Entity’s Protected Health Information, in compliance with
                  the requirements of 45 C.F.R. § 164.514. Business Associate shall be the owner of such de-identified
                  data.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  This Agreement is to be interpreted in accordance with HIPAA, the HITECH Act, and the regulations
                  promulgated thereunder, as amended from time to time.
                </Paragraph>
              </li>
            </ol>
          </section>
        </div>
      </div>
    </div>
  );
};
