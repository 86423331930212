import useResizeObserver from "@react-hook/resize-observer";
import * as React from "react";

interface Size {
  width: number;
  height: number;
}

export const useSize = (target: React.RefObject<HTMLElement>) => {
  const [size, setSize] = React.useState<Size>({ width: 0, height: 0 });

  React.useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};
