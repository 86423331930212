import { useLayoutEffect, useState } from "react";

import { Heading } from "@fronterahealth/frontera-ui-components";

import useMediaQuery from "@hooks/useMediaQuery";

const proportionOfWindowHeightForDemoHeight = 0.75;

const demoIframeURL = "https://capture.navattic.com/clyyss0su000009jrgwp7cm9g";

export const Tour: React.FC = () => {
  const isDesktop = useMediaQuery("(min-width: 960px)");

  const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);
  useLayoutEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
      id="tour-section"
      className={`flex flex-col ${isDesktop ? "px-20" : "px-4"} py-24 justify-start items-center relative w-full`}
    >
      <div className="max-w-screen-xl flex items-center flex-col justify-center text-center z-10">
        <Heading id="demo" type="h1">
          Take a Tour
        </Heading>
        <div className="border border-limestone-100 rounded-[32px] mt-8 p-4">
          <iframe
            className="border-8 border-text-primary rounded-[16px]"
            style={{
              maxHeight: screenHeight * proportionOfWindowHeightForDemoHeight,
              minHeight: screenHeight * proportionOfWindowHeightForDemoHeight,
              width: screenHeight * proportionOfWindowHeightForDemoHeight * 1.53,
              minWidth: screenHeight * proportionOfWindowHeightForDemoHeight * 1.53,
              maxWidth: screenHeight * proportionOfWindowHeightForDemoHeight * 1.53,
            }}
            src={demoIframeURL}
            allow="fullscreen"
            id="navattic-embed"
          ></iframe>
        </div>
      </div>
      <div className="bg-limestone-50 w-full h-[50vh] absolute bottom-0" />
    </div>
  );
};
